$lightGreen: #000;

.app {
  display: flex;
  justify-content: center;
  .container {
    width: 1024px;

    .link {
      text-decoration: none;
      color: inherit;
    }

    .auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;

      h1 {
        font-size: 20px;
        color: $lightGreen;
      }

      form {
        display: flex;
        flex-direction: column;
        padding: 25px;
        background-color: white;
        width: 200px;
        gap: 25px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
          outline: none;
        }

        button {
          padding: 10px;
          border: none;
          background-color: $lightGreen;
          cursor: pointer;
          color: white;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .navbar {
      margin-top:25px;
      .container {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
          img {
            width: 200px;
          }
        }
        .links {
          display: flex;
          align-items: center;
          gap: 10px;

          h6 {
            font-size: 16px;
            font-weight: 300;
          }

          span {
            cursor: pointer;
          }

          .write {
            color:$lightGreen;
          }
        }
      }
    }

    footer {
      margin-top: 100px;
      padding: 20px;
      background-color: $lightGreen;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color:#fff;
      font-size: 14px;
      img {
        height: 50px;
      }
      span {text-align: center; width:100%}
    }

    .imgarea{
      float: left;
      width: 350px;
      margin-right: 25px
    }

    .home {
      .posts {
        margin-top: 50px;
        display: inline-block;
        width: 100%;
        
            
    .headline {
      float: left;
      width: 100%;
      margin-top: 50px;
    }
    .headline h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .button_area {
      width: 100%;
      display: inline-block;
      margin-top:15px;
    }
    .button {
      background-color: $lightGreen;
      border:1px solid $lightGreen;
      color:#fff;
      cursor: pointer;
      padding: 10px 15px;
    }

    .input {
      float: left;
    outline: none;
    padding: 10px 15px;
    width: 250px;
    }

        .post {
          display: inline-block;
          width: 20%;
          margin-right: 5%;
          margin-bottom: 5%;

          .link {
            min-height: 200px;
          }

          .img {
            flex: 2;
            position: relative;

            &::after {
              content: "";
              width: 100%;
              height: 100%;
              background-color: $lightGreen;
              position: absolute;
              top: 20px;
              left: -20px;
              z-index: -1;
            }

            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
            }
          }

          .content {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
              font-size: 48px;
            }

            p {
              font-size: 18px;
            }

            button {
              width: max-content;
              padding: 10px 20px;
              border: none;
              cursor: pointer;
              background-color: white;
              border: 1px solid $lightGreen;
              color: $lightGreen;

              &:hover {
                border: 1px solid white;
                background-color: $lightGreen;
                color: black;
              }
            }
          }
        }
      }
    }

    //SINGLE

    .single {
      display: flex;
      gap: 50px;
      margin-top: 50px;

      .content {
        display: inline-block;

        img {
          width: 100%;
          object-fit: cover;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-weight: bold;
          }
        }

        .edit {
          display: flex;
          gap: 5px;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        h1 {
          font-size: 24px;
          color: #333;
          margin-top: 25px;
          display: inline-block;
        }

        p {
          text-align: justify;
          line-height: 30px;
        }
      }
      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 25px;

        h1 {
          font-size: 20px;
          color: #555;
        }

        .post {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }

          h2 {
            color: #555;
          }

          button {
            width: max-content;
            padding: 7.5px 15px;
            border: none;
            cursor: pointer;
            color: $lightGreen;
            background-color: white;
            border: 1px solid $lightGreen;

            &:hover {
              border: 1px solid white;
              background-color: $lightGreen;
              color: black;
            }
          }
        }
      }
    }

    //WRITE

    .imgPanel {
      max-width: 250px; margin-top: 20px;
    }

    .add {
      margin-top: 20px;
      display: flex;
      gap: 20px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editorContainer {
          height: 300px;
          overflow: scroll;
          border: 1px solid lightgray;

          .editor {
            height: 100%;
            border: none;
          }
        }
      }

      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          border-left: 1px solid lightgray;
          padding: 10px;
          flex: 1 1;
          display: inline-block;
          font-size: 13px;
          color: #555;;

          h1{
            font-size: 20px;
            margin-bottom:20px
          }

          .file{
            text-decoration: underline;
            cursor: pointer;
          }

          .buttons{
            display: flex;
            justify-content: space-between;
            margin-top:20px;

            :first-child{
              cursor: pointer;
              color: $lightGreen;
              background-color: white;
              border: 1px solid $lightGreen;
              padding: 3px 5px;
            }
            :last-child{
              cursor: pointer;
              color: white;
              background-color: $lightGreen;
              border: 1px solid $lightGreen;
              padding: 3px 5px;
            }
          }

          .cat{
            display: flex;
            align-items: center;
            gap: 2px;
            color: $lightGreen;
          }
        }
      }
    }  

  }
  
}